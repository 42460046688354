<!-- /*
 * @Author: chenxu chenxu@libawall.cn
 * @Date: 2023-05-05 16:31:47
 * @LastEditors: yangzebin@libawall.cn
 * @LastEditTime: 2024-04-18 18:16:00
 * @FilePath: \yda_web_manage\src\router\async-router.js
 * @Description:已审批列表
 *
 */ -->
<template>
  <template v-if="pageLoading">
    <div class="base-apply-list">
      <a-spin size="large" class="loading-animation" />
    </div>
  </template>
  <template v-else>
    <section>
      <section class="form-style">
        <!-- 头部 -->
        <a-form layout="inline" ref="headFormRef" :model="headFormState">
          <!-- 搜索框 -->
          <a-form-item label="流程主题:" class="form-center" name="processTopic">
            <a-input style="width: 200px;height: 32px;" v-model:value.trim="headFormState.processTopic" :maxlength="50"
              placeholder="请输入" allowClear />
          </a-form-item>
          <!-- 流程名称 -->
          <a-form-item label="申请人:" name="processStaffName">
            <a-input style="width: 200px;height: 32px;" v-model:value.trim="headFormState.processStaffName"
              :maxlength="30" placeholder="请输入" allowClear />
          </a-form-item>
          <!-- 文件类型 -->
          <a-form-item label="流程类型:" name="processMold">
            <a-select style="width: 200px;height: 32px;" v-model:value="headFormState.processMold" allowClear
              placeholder="请选择" class="form-select">
              <a-select-option v-for="value in processMoldOptions" :key="value.code" :value="value.code">
                {{
    value.desc
  }}
              </a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item label="流程名称:" name="processName">
            <a-input style="width: 200px;height: 32px;" v-model:value.trim="headFormState.processName" :maxlength="30"
              placeholder="请输入" allowClear />
          </a-form-item>
          <a-form-item label="申请时间:" name="submitTime">
            <a-range-picker :disabledDate="disabledDate" separator="→" allowClear
              v-model:value="headFormState.submitTime" style="width: 240px;height: 32px;">
              <template #suffixIcon>
                <CalendarOutlined />
              </template>
            </a-range-picker>
          </a-form-item>
          <!-- 重置/查询 -->
          <a-form-item class="form-btns">
            <a-button @click="reset">重置</a-button>
            <a-button class="form-btn" @click="searchList">查询</a-button>
          </a-form-item>
        </a-form>
      </section>
      <!-- 表格数据 -->
      <section class="table-style">
        <div class="table-head">
          <span class="title">用印列表</span>
        </div>
        <a-table :columns="approvedColumns" :pagination="pagination" :dataSource="dataSource"
          @change="handleTableChange" :loading="tableLoading" :rowKey="index" ref="tableRef" :scroll="{ x: 400 }">
          <!-- 序号 -->
          <template #id="{ index }">{{ (pagination.index - 1) * pagination.pageSize + index + 1 }}</template>
          <!-- 流程主题 -->
          <template #processTopic="{ text, record }">
            <div class="text-box">
              <a-tooltip placement="bottomLeft" v-if="text?.length > 13">
                <template #title>
                  <span>{{ text }}</span>
                </template>
                <span :class="isHome ? 'table-text-color' : ''" @click="isHome ? openModal(record) : ''"
                  :style="isHome ? 'cursor: pointer' : ''">
                  {{ text ?? '-' }}
                </span>
              </a-tooltip>
              <span v-else :class="isHome ? 'table-text-color' : ''" @click="isHome ? openModal(record) : ''"
                :style="isHome ? 'cursor: pointer' : ''">
                {{ text ?? '-' }}
              </span>
            </div>
          </template>
          <!-- 流程类型 -->
          <template #processMold="{ text }">
           {{processType[text-1].name  ?? '-' }}
          </template>
          <!-- 流程名称 -->
          <template #processName="{ text }">
            <div class="text-box">
              <a-tooltip placement="bottomLeft" v-if="text?.length > 13">
                <template #title>
                  <span>{{ text }}</span>
                </template>
                {{ text ?? '-' }}
              </a-tooltip>
              <span v-else>{{ text ?? '-' }}</span>
            </div>
          </template>
          <!-- 申请人 -->
          <template #applyStaffName="{ text, record }">
          <div class="text-box">
            <a-tooltip placement="bottomLeft" v-if="text?.length + record.applyDepartmentName.length > 13">
              <template #title>
                <span>{{ text }}-{{ record.applyDepartmentName }}</span>
              </template>
              <span v-if="text"> {{ text ?? '--' }}-{{ record.applyDepartmentName }}</span>
              <span v-else>--</span>
            </a-tooltip>
            <span v-if="text"> {{ text ?? '--' }}-{{ record.applyDepartmentName }}</span>
            <span v-else>--</span>
          </div>
        </template>
          <!-- 审批状态  审批中0，同意1，拒绝2，撤销3 -->
          <template #examineStatus="{ text }">
            <span class="dotted" :style="{ background: examineStatusObj[text].color }"></span>
            <span>{{ examineStatusObj[text].name }}</span>
          </template>
          <!-- 操作 -->
          <template #action="{ record }">
            <a @click="openModal(record)" class="table-text-color">查看</a>
          </template>
        </a-table>
      </section>
    </section>
  </template>
</template>

<script>
import { defineComponent, onMounted, reactive, ref, toRefs } from 'vue'
import { approvedColumns } from '../columns'
import { getApprovedList } from '@/apis/approval'
import { examineStatusObj, paginationParams, searchType, approvalStatusList ,processType} from '@/utils/constData'
import { setTablePageFooterStyle } from '@/utils/utils'
import { useRouter, useRoute } from 'vue-router'
import { getEnums } from '@/apis/user'
import moment from 'moment'

export default defineComponent({
  components: {},
  setup() {
    const pageLoading = ref(true)
    const router = useRouter()
    const route = useRoute()
    const tableRef = ref()
    const headFormRef = ref()
    const headFormState = reactive({
      processTopic: undefined, //流程主题
      processStaffName: undefined,//申请人
      processMold: undefined,//流程类型
      processName: undefined,//流程名称
      submitTime: [],//申请时间
    })
    const state = reactive({
      documentId: undefined,
      pagination: {
        ...paginationParams
      },
      dataSource: [],
      tableLoading: true,
      processType:processType,
      currentNum: 1,
      searchTypeOptions: [], //搜索项类型
      documentTypeOptions: [], //文件类型搜索选项
      processMoldOptions: [] //流程类型搜索选项
    })

    const getList = async () => {
      // 列表展示数据
      state.tableLoading = true
      state.dataSource = []
      const params = {
        pageIndex: state.pagination.current,
        pageSize: state.pagination.pageSize,
        processTopic: headFormState.processTopic, //模糊查询内容
        processStaffName: headFormState.processStaffName, //搜索项类型
        processMold: headFormState.processMold, //流程名称模糊搜索
        processName: state.timeSprocessNameort,
        startTime: headFormState.submitTime[0] ? moment(headFormState.submitTime[0]).format('YYYY-MM-DD 00:00:00' ) : '', //开始时间
        endTime: headFormState.submitTime[1] ? moment(headFormState.submitTime[1]).format('YYYY-MM-DD 00:00:00') : '', //结束时间
      }
      const res = await getApprovedList(params)
      state.dataSource = res.data
      state.pagination.total = res.totalItem
      state.pagination.index = res.pageIndex
      state.tableLoading = false
      setTablePageFooterStyle(tableRef)
      state.documentId = undefined
    }
    const openModal = async current => {
      console.log(current);
      router.push({
        path: '/approval/alreadyApproval/approveddetail',
        query: {
          type: 1,
          id: current.id,
          documentId: current.documentId,
          processInstanceId: current.processInstanceId,
          taskId: current.taskId,
          hasCover: current.hasCover,
          dataId: current.dataId,
          processMold:current.processMold
       }
      })
    }
    //搜索
    const searchList = () => {
      state.pagination.current = 1
      getList()
    }
    //重置
    const reset = () => {
      headFormRef.value.resetFields()
      headFormState.searchType = 1
      state.pagination.current = 1
      getList()
    }
    const getprocessMold = (value) => {
      const textMap = state.processMoldOptions
      return textMap[value-1]?.desc || '-';
    }
    // 日期选择器：当天之后不可选
    const disabledDate = current => {
      return current && current > moment().endOf('day')
    }
    const handleTableChange = ({ current, pageSize }) => {
      // 列表的页码change事件
      state.pagination.current = current
      state.currentNum = current
      state.pagination.pageSize = pageSize
      state.dataSource = []
      getList()
    }
    // 搜索框点击
    // const clickFocus = () => {
    // inputBox.value.focus()
    // }

    // 第三方跳转
    const thirdparty = () => {
      if (route.query.documentId) {
        openModal({
          status: route.query.sealType === '1' ? 2 : 3,
          documentId: route.query.documentId,
          flag: route.query.flag,
          sealId: route.query.sealId,
          sealType: route.query.sealType === '1' ? 1 : 2
        })
        state.documentId = route.query.documentId
      }
    }
    // 是否为企业版 列表数据不同
    // const detailType = ref()
    // const getDetailsType = async () => {
    //   const res = await getDetailsApplyType()
    //   detailType.value = res.data
    //   console.log('detailType的值', detailType.value)
    //   if (detailType.value === 1) {
    //     //政务版
    //     isShow.value = true
    //     state.columns = approvedColumn
    //   } else {
    //     //企业版
    //     isShow.value = false
    //     state.columns = approvedColumns
    //   }
    // }
    const getprocessMoldOptions = () => {
      let res = getEnums({ kind: 'PROCESS_MOLD' }).then(res => {
        if (res.success) {
          state.processMoldOptions = res.data
          console.log('6666666666666666', res.data)
        }
      })
    }
    onMounted(async () => {
      if (route.query.documentId) state.documentId = route.query.documentId
      await getprocessMoldOptions()

      // getDetailsType()
      await getList()
      pageLoading.value = false
      setTimeout(() => {
        thirdparty()
      }, 500)
    })

    return {
      // isShow,
      // addApply,
      ...toRefs(state),
      searchList,
      handleTableChange,
      openModal,
      examineStatusObj,
      approvalStatusList,
      tableRef,
      // clickFocus,
      // inputBox,
      headFormState,
      headFormRef,
      reset,
      // detailType,
      searchType,
      approvedColumns,
      pageLoading,
      disabledDate,
      getprocessMold
    }
  }
})
</script>

<style lang="scss" scoped>
.base-apply-list {
  min-height: calc(100vh - 180px);
}

.loading-animation {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

::v-deep .ant-select {
  .ant-select-selector {
    height: 32px;
    border-radius: 4px;
    line-height: 32px;
  }

  .ant-select-selection-placeholder {
    line-height: 32px;
  }

  .ant-select-selection-item {
    line-height: 32px;
  }
}

.dotted {
  width: 7px;
  height: 7px;
  margin-right: 5px;
  display: inline-block;
  border-radius: 50%;
  transform: translateY(-1px);
}

.text-box {
  max-width: 13em;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
